import Vuex from 'vuex';
import Lots from "./services/Lots";
import User from "./services/user";
import jwt_decode from "jwt-decode";
import router from './router/router';
import Public from "./services/Public";
import Allotments from "./services/Allotments";
import {http, httpAuth} from './services/config';


const stores = new Vuex.Store({
    state: {
        itemsValidations: [],
        itemsValidationsRequired: [],
        image: null,
        pinned: false,
        toggled: null,
        companies: [],
        loopLots: null,
        sale: null,
        isLoading: null,
        isCompany: null,
        allotments: null,
        showModal: false,
        oneToExpire: null,
        selectedClients: null,
        lotModified: null,
        fiveToExpire: null,
        imageCropped: null,
        allotmentLoad: null,
        hasAllotments: null,
        expirationTime: null,
        disableBeforeOne: null,
        hastwoAllotments: null,
        disableBeforeFive: null,
        savedMoviments: null,
        showReahtorizationAlert: false,
        dateNow: null,
        menssageAllotmentRequired: false,
        itemId: null,
        tokenSubmit: {
            scope: "",
            refresh_token: null,
            client_id: process.env.VUE_APP_CLIENT_ID,
            client_secret: process.env.VUE_APP_CLIENT_SECRET,
            grant_type: process.env.VUE_APP_GRANT_REFRESH_TOKEN,
        },
        paginate: [
            {id: 'selecione', text: 'Selecione'},
            {id: 25, text: 25},
            {id: 50, text: 50},
            {id: 75, text: 75},
            {id: 100, text: 100},
        ]
    },

    getters: {
        sessionStatus() {
            stores.dispatch('ActionRefreshTokenTime')
        }
    },

    actions: {
        async ActionLoadSession({dispatch}) {
            await User.loadSession().then((resp) => {
                if (resp.data !== true){
                    dispatch('ActionSingOut')
                    router.push({name: 'Login'});
                }
            }).catch(() => {
                dispatch('ActionSingOut')
                router.push({name: 'Login'});
            }).catch(error => {
                console.error(error);
            });
        },

        ActionSingOut({commit}) {
            setBearerToken('')
            commit('stopLopLots');
            commit('checkAllotments', false);
            localStorage.removeItem('companyId');
            localStorage.removeItem('companies');
            localStorage.removeItem('allotmentId');
            localStorage.removeItem('proposalAllotmentId');
            localStorage.removeItem('reserveAllotmentId');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('firstProfileClosed');
            localStorage.removeItem('disable_before_one');
            localStorage.removeItem('disable_before_five');
        },

        ActionCheckToken({dispatch}) {

            const access_token = localStorage.getItem('access_token');

            if (!access_token) {
                return Promise.reject(new Error('Token inválido'));
            }

            setBearerToken(access_token);
            return dispatch('ActionLoadSession');
        },

        ActionDoLogin({dispatch}, payload) {

            setBearerToken(payload.access_token);
            localStorage.setItem('access_token', payload.access_token);
            localStorage.setItem('refresh_token', payload.refresh_token);
            localStorage.setItem('companies', JSON.stringify(payload.companies));

            dispatch('ActionLoadSession');
        },

        async ActionRefreshToken({dispatch, commit, state}) {

            commit('startPayloadToken');

            await User.refreshToken(state.tokenSubmit).then(resp => {

                setBearerToken('');
                localStorage.removeItem('companies');
                localStorage.removeItem('allotmentId');
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('firstProfileClosed');
                localStorage.removeItem('disable_before_one');
                localStorage.removeItem('disable_before_five');

                dispatch('ActionDoLogin', resp.data);

                commit('resetExpirationTime');
                commit('enableReauthorization', false);

            });

            dispatch('ActionRefreshTokenTime');
        },

        ActionDateNow({commit}) {
            setInterval(() => {
                let dateString = new Date().toLocaleString("pt-BR", {timeZone: "America/Sao_Paulo"});
                dateString = dateString.split('/')
                commit('DateNow', (`${dateString[0]}/${dateString[1]}/${dateString[2]}`));
            }, 1000);
        },
        ActionRefreshTokenTime({dispatch, state, commit}) {
            commit('resetExpirationTime');
            commit('disbleModalBeforeOne');
            commit('disbleModalBeforeFive');
            commit('enableReauthorization', false);
            let intervalo = setInterval(() => {
                let currentTime = new Date().getTime() / 1000.0;
                if (currentTime > state.expirationTime) {
                    clearInterval(intervalo);
                    dispatch('ActionLoadSession');
                    commit('enableReauthorization', false);
                } else {
                    if (currentTime > state.fiveToExpire && !state.disableBeforeFive) {
                        localStorage.setItem('disable_before_five', currentTime > state.fiveToExpire);
                        commit('enableReauthorization', 5);
                        commit('disbleModalBeforeFive');

                    } else if (currentTime > state.oneToExpire && !state.disableBeforeOne) {
                        localStorage.setItem('disable_before_one', currentTime > state.oneToExpire);
                        commit('enableReauthorization', 1);
                        commit('disbleModalBeforeOne');
                    } else {
                        commit('enableReauthorization', 2);
                    }
                }
            }, 30000);
        },

        ActionListLotModified({commit}, stop = false) {
            if (stop) {
                commit('stopLopLots');
            } else {
                commit('startLopLots');
            }
        },

        ActionSelectedClients({commit}, payload) {
            commit('selectedClients', payload);
        },

        ActionListLotModifiedPublic({commit}, companyId) {
            commit('startLopLots', companyId);
        },

        ActionAlterSaleValue({commit}, value) {
            commit('alterSaleValue', value);
        },

        ActionSaveMovimentCaseReturn({commit}, value) {
            commit('saveMovimentReturn', value);
        },

        ActionUpdateMenu({commit}, name, isCompany = false) {
            if (document.getElementsByClassName('active-page-link')[0]) {
                document.getElementsByClassName('active-page-link')[0].classList.remove('active-page-link');
            }

            document.getElementById(name).classList.add('active-page-link');
            commit('updateIsCompany', isCompany);
        },

        ActionCheckAllotments({commit}, companyId) {
            Allotments.listForMenu(companyId).then(resp => {
                console.log('ActionCheckAllotments --> ', resp.data)
                commit('listAllotments', resp.data)
                commit('checkAllotments', resp.data.validations ? false : true);
            });
        },

        ActionCloseSidMenuPhoneMod({commit, state}) {
            if (state.toggled) {
                document.getElementById('wrapper').classList.remove('toggled');
                document.getElementById('configSubmenu').classList.remove('show');

                commit('toggleSidebar');
            }
        },

        ActionCloseModalsToLogin() {
            if (document.getElementById('closeModal')) {
                document.getElementById('closeModal').click();
            } else if (document.getElementById('closeX')) {
                document.getElementById('closeX').click();
            } else if (document.getElementById('closeProfile')) {
                document.getElementById('closeProfile').click();
            }
        }
    },

    mutations: {
        setItemsValidations(state, data){
            state.itemsValidations = data;
            state.itemsValidationsRequired = data.filter(i => i.is_required);
        },
        listAllotments(state, allotments) {
            state.allotmentLoad = true;
            if (!allotments.validations) {
                state.allotments = allotments;
                state.hastwoAllotments = allotments.length == 1 ? false : true;
            }
        },

        startLopLots(state, companyId = null) {
            let time;

            state.loopLots = setInterval(() => {

                time = new Date();
                let time2 = new Date(time.valueOf() - time.getTimezoneOffset() * 60000);
                time = time2.toISOString().slice(0, 19).replace('T', ' ');

                if (companyId) {
                    let allotment_id = localStorage.getItem('publicAllotmentId');
                    Public.LotListModified(companyId, time, allotment_id).then(resp => {
                        state.lotModified = resp.data;
                    })
                } else {
                    let allotment_id = localStorage.getItem('allotmentId');
                    if (!allotment_id) {
                        allotment_id = state.allotments[0].id
                    }
                    Lots.listModified(time, allotment_id).then(resp => {
                        state.lotModified = resp.data;
                    })
                }

            }, process.env.VUE_APP_LOOP_TIME_LOTS);
        },

        stopLopLots(state) {
            clearInterval(state.loopLots);
        },

        checkAllotments(state, boolean) {
            state.hasAllotments = boolean;
        },

        enableReauthorization(state, number) {
            state.showReahtorizationAlert = number;
        },
        DateNow(state, date) {
            state.dateNow = date;
        },
        disbleModalBeforeFive(state) {
            state.disableBeforeFive = localStorage.getItem('disable_before_five');
        },

        alterSaleValue(state, payload) {
            state.sale = payload;
        },

        saveMovimentReturn(state, payload) {
            state.savedMoviments = payload;
        },

        selectedClients(state, payload) {
            state.selectedClients = payload;
        },

        disbleModalBeforeOne(state) {
            state.disableBeforeOne = localStorage.getItem('disable_before_one');
        },

        resetExpirationTime(state) {
            state.expirationTime = jwt_decode(localStorage.getItem('access_token')).exp;
            let expirationTimeFive = new Date(state.expirationTime * 1000);
            let expirationTimeOne = new Date(state.expirationTime * 1000);
            expirationTimeFive.setMinutes(expirationTimeFive.getMinutes() - 2)
            expirationTimeOne.setMinutes(expirationTimeOne.getMinutes() - 1)
            state.fiveToExpire = expirationTimeFive.getTime() / 1000;
            state.oneToExpire = expirationTimeOne.getTime() / 1000;
        },

        startPayloadToken(state) {
            state.tokenSubmit.refresh_token = localStorage.getItem('refresh_token');
        },

        updateIsCompany(state, value) {
            state.isCompany = value;
        },

        displayMenssageAllotmentRequired(state) {
            state.menssageAllotmentRequired = true;
        },

        pinSidebar(state) {
            state.pinned = !state.pinned;
        },

        toggleSidebar(state) {
            state.toggled = state.toggled == null ? true : !state.toggled;
        },

        startCompaniesToken(state) {
            state.companies = JSON.parse(localStorage.getItem('companies'));
        },

        showModalSelectCompany(state, boolean) {
            state.showModal = boolean;
        },

        setImage(state, image) {
            state.image = image;
        },

        setImageCropped(state, image) {
            state.imageCropped = image;
        },

        changeLoading(state, boolean) {
            state.isLoading = boolean;
        },
        setItemId (state, id) {
            state.itemId = id
        }
    }
});

const setBearerToken = token => {
    httpAuth.defaults.headers.authorization = `Bearer ${token}`;
    http.defaults.headers.authorization = `Bearer ${token}`;
    http.defaults.headers.common['company-id'] = localStorage.getItem('companyId');
}

export default stores;
