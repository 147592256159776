import {http, httpAuth} from './config';

export default {
    checkUserIdAndCompanyIdExists: data => http.post('api/v1/publica/check/usuario/companhia', data),

    checkPermissionPublicArea: (company, allotment) => http.get(`api/v1/publica/loteamentos/permitirAreaPublica?company-id=${company}&allotmentId=${allotment}`),

    indexSituation: companyId => http.get('api/v1/publica/propriedades/situacao', {headers: {'company-id': companyId}}),

    listAllotment: companyId => http.get('api/v1/publica/loteamentos/listar', {headers: {'company-id': companyId}}),

    blocksList: (companyId, allotmentId) => http.get('api/v1/publica/lotes/quadras/listar', {
        headers: {
            'company-id': companyId,
            'allotment-id': allotmentId,
        },
    }),

    lotsByAllotmentAndBlock: (companyId, allotmentId, blockId) => http.get(`api/v1/publica/lotes/${allotmentId}/${blockId}`,
        {headers: {'company-id': companyId}}),

    LotShow: (companyId, loteId) => http.get(`api/v1/publica/lotes/${loteId}`, {headers: {'company-id': companyId}}),

    LotListModified: (companyId, time, allotmentId) => http.get(`api/v1/publica/listarLotesModificados/${time}`, {
        headers: {
            'company-id': companyId,
            'allotment-id': allotmentId,
        },
    }),

    ReserveCreate: (companyId, reserve) => http.post('api/v1/publica/reservas', reserve, {headers: {'company-id': companyId}}),

    CreateClient: (client, companyId, key) => http.post('api/v1/publica/clientes', client, {
        headers: {
            'company-id': companyId,
            'authorization': key,
        },
    }),

    showClient: (id, companyId) => http.get(`api/v1/publica/clientes/${id}`, {headers: {'company-id': companyId}}),

    listByCep: cep => httpAuth.get(`v1/publica/cidades/${cep}`),

    getAllotmentPermissions: (allotmentId, companyId) => http.get(`api/v1/publica/loteamentos/permissions/${allotmentId}`, {headers: {'company-id': companyId}}),

    getInstallmentTypes: (allotmentId, identification, companyId) => http.get('api/v1/publica/tipo-parcela-externo', {
        headers: {
            'allotment-id': allotmentId,
            'company-id': companyId ?? null,
        },
        params: {identification},
    }),
};
