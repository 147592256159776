import Vuex from './vuex';
import App from './App.vue';
import { createApp } from 'vue';
import pt from "./locales/pt.json";
import router from './router/router';
import { createI18n } from 'vue-i18n';
import VueTheMask from 'vue-the-mask';
import Toast from "vue-toastification";
import VueGtag from 'vue-gtag-next';
import GAuth from 'vue3-google-oauth2';
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import axios from 'axios';
import VueAxios from 'vue-axios';
import './registerServiceWorker';

const messages = {
    pt,
}

const i18n = new createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || 'pt',
    fallbackLocale: process.env.VUE_APP_I18N_LOCALE || 'pt',
    messages
})

const gAuthOptions = { clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID, scope: 'profile', prompt: 'consent', fetch_basic_profile: true }

const app = createApp(App)


app.use(i18n)
app.use(Vuex)
app.use(router)
app.use(VueTheMask)
app.use(VueApexCharts)
app.use(VueSweetalert2)
app.use(VueAxios, axios)
app.use(GAuth, gAuthOptions)
app.use(Toast, {position: 'top-right'})
app.use(VueGtag, {
    property: {
        id: "UA-37317143-1",
        params: {
            user_id: "12345",
            send_page_view: true,
            globalObjectName:"R2 Soft",
            app_name: "R2 Disponibilidade",
            linker: {
                domain: [`${process.env.VUE_APP_BASE_URL}`]
            }
        }
    }
})
app.mount('#app')
