import {http} from './config';

export default {
    show: lotId => http.get(`api/v1/lotes/${lotId}`),
    showOnlyReserve: lotId => http.get(`api/v1/lotes/${lotId}`,{params:{onlyReserve:true}}),
    showOnlyReadjustment: lotId => http.get(`api/v1/lotes/${lotId}`,{params:{onlyReadjustment:true}}),
    list: () => http.get('api/v1/lotes/listar'),

    create: lot => http.post('api/v1/lotes', lot),

    update: (lotId, lot) => http.put(`api/v1/lotes/${lotId}`, lot),

    delete: lotId => http.delete(`api/v1/lotes/${lotId}`),

    blocks: allotmentId => http.get('api/v1/lotes/quadras/listar', {headers: {'allotment-id': allotmentId}}),

    getLotsByAllotment: allotmentId => http.get(`api/v1/lotes/loteamento/${allotmentId}`),

    blocksList: (allotmentId, withReserve, statusId = null) => http.get('api/v1/lotes/quadras/listar', {
        params: {'with-reserve': withReserve, statusId},
        headers: {'allotment-id': allotmentId},
    }),

    blocksListActivesAndBlocked: allotmentId => http.get(`api/v1/lotes/quadras/listarAtivosBloqueados/${allotmentId}`),

    blocksListAvailable: allotmentId => http.get('api/v1/lotes/quadras/listarDisponiveis', {headers: {'allotment-id': allotmentId}}),

    lotsByAllotmentAndBlock: (allotmentId, blockId, statusId = null) => http.get(`api/v1/lotes/${allotmentId}/${blockId}`, {params:{statusId}}),

    listModified: (time, allotmentId) => http.get(`api/v1/lotes/listarModificados/${time}`, {headers: {'allotment-id': allotmentId}}),

    showLotProperties: (allotmentId, blockId) => http.get(`api/v1/lotes/verDisponiveis/${allotmentId}/${blockId}`),

    getTypesLotes: allotmentId => http.get('api/v1/tipoPropriedades/listar', {headers: {'allotment-id': allotmentId}}),

    import: (file, allotmentId) => {
        const formData = new FormData();
        formData.append('file', file);
        return http.post('api/v1/importar/lotes', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'allotment-id': allotmentId,
            },
        });
    },

    export: allotmentId => http.get('/api/v1/exportar/lotes', {
        responseType: 'blob',
        headers: {'allotment-id': allotmentId},
    }),
};
