import stores from "../vuex"
import { http } from "../services/config";

export default async(to, from, next) => {
    const token = http.defaults.headers.authorization;

    if (to.name != 'Login' && to.name != 'SecurityLogin') {
        try {
            if (to.name == 'Singup' || to.name == 'PublicLot' || to.name == 'PublicClient' ||
                to.name == 'RecoverPassword' || to.name == 'ResetPassword' || to.name == 'CreatePassword' ||
            to.name == 'indexAreaPublica' || to.name == 'indexPublicMapas') {
                next()
            } else {
                await stores.dispatch('ActionCheckToken')
                next()
            }
        } catch (error) {
            next({ name: 'Login' })
        }
    } else {
        if ((to.name == 'Public' || to.name == 'Singup') && token) {
            next({ name: 'Home' })
        } else {
            next()
        }

        stores.dispatch('ActionCloseModalsToLogin');
    }

    if (from.name == 'IndexLotes') {
        stores.dispatch('ActionListLotModified', true);
    }

    stores.dispatch('ActionCloseSidMenuPhoneMod');
    stores.dispatch('ActionUpdateMenu', to.name, to.name == 'Company')
}
