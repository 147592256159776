import axios from "axios";

export const http = axios.create({
    baseURL: process.env.VUE_APP_BACK_END
})

export const httpAuth = axios.create({
    baseURL: process.env.VUE_APP_URL_AUTH
})

//http://zap.r2app.com.br:3003/numberIsValid/5511949442413
// export const httpZap = axios.create({
//     baseURL: 'http://zap.r2app.com.br:3003' //TODO passar para o dot env
// })
